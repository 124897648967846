import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  Modal,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import bg from "../../assets/images/sub_bg.jpg";
import { DataContext } from "../../store";

export default function Navigation() {
  const [navbarBg, setNavbarBg] = useState("transparent");
  const [linkColor, setLinkColor] = useState("white");
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { projects, blogs } = useContext(DataContext);

  const [searchQuery, setSearchQuery] = useState("");

  const allItems = [
    ...projects.map((project) => ({ ...project, type: "project" })),
    ...blogs.map((blog) => ({ ...blog, type: "blog" })),
  ];
  const filteredResults = allItems.filter(
    (item) =>
      searchQuery.trim() !== "" && // Ensure searchQuery is not empty or whitespace
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  console.log(filteredResults);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg("black");
        setLinkColor("white");
      } else {
        setNavbarBg("transparent");
        setLinkColor("white");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Navbar
        expand="lg"
        className={`fixed-top w-100 ${
          navbarBg === "transparent" ? "navbar-light" : "navbar-dark"
        }`}
        style={{
          backgroundColor: navbarBg,
          transition: "background-color 0.3s ease",
        }}
        collapseOnSelect
      >
        <Container fluid style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <Navbar.Brand
            as={Link}
            to="/"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src="ji_construct_logo.png"
              alt="Logo"
              height="40"
              width={150}
              className="mx-3"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" className="mx-4" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            style={{
              backgroundSize: "cover",
              color: "white",
              width: "40%",
            }}
            className="sidebar-background"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ms-auto d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mx-3"
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => setShowSearchModal(true)}
                />
                {[
                  { name: "Home", path: "/" },
                  { name: "News", path: "/news" },
                  { name: "Project", path: "/project" },
                  { name: "About Us", path: "/about_us" },
                  { name: "Contact Us", path: "/contact_us" },
                ].map((link, index) => (
                  <Nav.Link
                    key={index}
                    as={NavLink}
                    to={link.path}
                    exact
                    activeClassName="active-link"
                    style={{
                      color: "white",
                      marginLeft: "20px",
                      transition: "color 0.3s ease",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {link.name}
                  </Nav.Link>
                ))}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        centered
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Modal.Body
          style={{
            height: "100vh",
            display: "flex",
            backgroundImage: `url(${bg})`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h4 style={{ color: "#FFD700", marginBottom: "20px" }}>
            Search Projects & Blogs
          </h4>
          <Form>
            <Form.Group controlId="searchInput">
              <Form.Control
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                style={{
                  fontSize: "18px",
                  padding: "10px",
                  width: "100%",
                  border: "2px solid #blue",
                  borderRadius: "5px",
                }}
              />
            </Form.Group>
          </Form>
          <div
            style={{
              marginTop: "20px",
              maxHeight: "300px",
              overflowY: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            {filteredResults.length > 0
              ? filteredResults.map((item, index) => (
                  <div key={index} style={{ margin: "10px 0" }}>
                    <Link
                      to={`/${
                        item.type === "project"
                          ? "project_detail"
                          : "blog_detail"
                      }?id=${item.id}`}
                      style={{
                        color: "#FFD700",
                        fontSize: "16px",
                        textDecoration: "none",
                        display: "block",
                        padding: "10px",
                        backgroundColor: "#333",
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      {item.title}
                    </Link>
                  </div>
                ))
              : searchQuery !== "" && (
                  <p style={{ color: "#FFD700" }}>No results found.</p>
                )}
          </div>
          <button
            className="btn btn-danger mt-3"
            onClick={() => setShowSearchModal(false)}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>

      <style>{`
        .sidebar-background.offcanvas.show {
          background-image: url(${bg});
        }

        @keyframes slideIn {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }

        .active-link {
          color: blue !important;
          border-bottom: 2px solid blue;
        }

        .navbar-nav .nav-link:hover {
          color: blue !important;
        }
      `}</style>
    </>
  );
}
