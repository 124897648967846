import React, { useContext, useState, useEffect } from "react";
import bg from "../../assets/videos/dummy/project.mp4";
import { Link } from "react-router-dom";
import { DataContext } from "../../store";
import { Helmet } from "react-helmet-async";

export default function Grid() {
  const { projects, backend_url, truncateContent } = useContext(DataContext);
  const [filteredProjects, setFilteredProjects] = useState([]);

  // Sort projects by creation date (newest first)
  const sortedProjects = projects.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // Count categories
  const categoryCounts = {};
  sortedProjects.forEach((project) => {
    project.Categories.forEach((category) => {
      categoryCounts[category.name] = (categoryCounts[category.name] || 0) + 1;
    });
  });

  const categories = [{ name: "All", amount: projects.length }].concat(
    Object.keys(categoryCounts).map((categoryName) => ({
      name: categoryName,
      amount: categoryCounts[categoryName],
    }))
  );

  // Set initial projects to show all
  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  // Filter projects by category
  const filterProjects = (category) => {
    if (category === "All") {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) =>
        project.Categories.some((cat) => cat.name === category)
      );
      setFilteredProjects(filtered);
    }
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Ji Construct | Projects</title>
      </Helmet>

      {/* Video Background */}
      <div
        className="position-relative overflow-hidden"
        style={{ height: "300px" }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity: 0.8,
          }}
        >
          <source src={bg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="container text-center text-white d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Projects Grid</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center bg-transparent text-white">
              <li className="breadcrumb-item">
                <Link to="/" className="text-white">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Project Grid
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Project Categories */}
      <div className="container py-4">
        <div className="d-flex justify-content-center mb-4">
          <ul className="nav nav-pills">
            {categories.map((cat, index) => (
              <li className="nav-item" key={index}>
                <button
                  className={`nav-link ${
                    index === 0 ? "active" : ""
                  } text-uppercase`}
                  onClick={() => filterProjects(cat.name)}
                >
                  {cat.name} ({cat.amount})
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Projects Grid */}
        <div className="row">
          {filteredProjects.map((project, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-stretch"
            >
              <div className="card border-0 shadow h-100">
                <img
                  src={`${backend_url}/${project.show}`}
                  className="card-img-top"
                  alt={project.title}
                  style={{ height: "250px", objectFit: "cover" }}
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">
                    {truncateContent(project.title, 4)}
                  </h5>
                  <p className="card-text">
                    {truncateContent(project.description, 20)}
                  </p>
                  <Link
                    to={{
                      pathname: "/project_detail",
                      search: `?id=${project.id}`,
                    }}
                    className="btn btn-primary mt-auto text-uppercase"
                  >
                    View Project
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
